import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import ArticlePage from "./abstract-article"
import { japaneseEvent } from "../articleTypes"

const JapaneseEventPage = ({ data, ...props }) => {
  return (
    <ArticlePage
      {...props}
      article={data.japaneseEvent}
      articleType={japaneseEvent}
      articleTypeTag="japanese-event"
      articleTypePath="/japanese-events"
    />
  )
}
JapaneseEventPage.propTypes = {
  data: PropTypes.any,
}

export const query = graphql`
  query($slug: String!) {
    japaneseEvent: markdownRemark(
      fields: { contentKind: { eq: "japanese-event" }, slug: { eq: $slug } }
    ) {
      ...TinaRemark
      ...ArticlePageFragment
    }
  }
`

export default JapaneseEventPage
